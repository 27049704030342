export default {
  format: {
    date: 'M/d/yyyy',
    time: 'p',
    dateTime: '$t(format:date) $t(format:time)',
    longDate: 'MMM d',
    longDateTime: "MMMM d 'at' p",
    fullDate: 'MMM d, y',
    fullDateTime: "MMMM d, y 'at' p",
  },

  translation: {
    common: {
      aboutPlanka: 'About',
      account: 'Account',
      actions: 'Actions',
      addAttachment_title: 'Add Attachment',
      addComment: 'Add comment',
      addManager_title: 'Add Manager',
      addMember_title: 'Add Member',
      addUser_title: 'Add User',
      administrator: 'Administrator',
      all: 'All',
      allChangesWillBeAutomaticallySavedAfterConnectionRestored:
        'All changes will be automatically saved<br />after connection restored.',
      areYouSureYouWantToDeleteThisAttachment: 'Are you sure you want to delete this attachment?',
      areYouSureYouWantToDeleteThisBoard: 'Are you sure you want to delete this board?',
      areYouSureYouWantToDeleteThisCard: 'Are you sure you want to delete this card?',
      areYouSureYouWantToDeleteThisComment: 'Are you sure you want to delete this comment?',
      areYouSureYouWantToDeleteThisLabel: 'Are you sure you want to delete this label?',
      areYouSureYouWantToDeleteThisList: 'Are you sure you want to delete this list?',
      areYouSureYouWantToDeleteThisProject: 'Are you sure you want to delete this project?',
      areYouSureYouWantToDeleteThisTask: 'Are you sure you want to delete this task?',
      areYouSureYouWantToDeleteThisUser: 'Are you sure you want to delete this user?',
      areYouSureYouWantToLeaveBoard: 'Are you sure you want to leave the board?',
      areYouSureYouWantToLeaveProject: 'Are you sure you want to leave the project?',
      areYouSureYouWantToRemoveThisManagerFromProject:
        'Are you sure you want to remove this manager from the project?',
      areYouSureYouWantToRemoveThisMemberFromBoard:
        'Are you sure you want to remove this member from the board?',
      attachment: 'Attachment',
      attachments: 'Attachments',
      authentication: 'Authentication',
      background: 'Background',
      board: 'Board',
      boardNotFound_title: 'Board Not Found',
      canComment: 'Can comment',
      canEditContentOfBoard: 'Can edit the content of the board.',
      canOnlyViewBoard: 'Can only view the board.',
      cardActions_title: 'Card Actions',
      cardNotFound_title: 'Card Not Found',
      cardOrActionAreDeleted: 'Card or action are deleted.',
      color: 'Color',
      copy_inline: 'copy',
      createBoard_title: 'Create Board',
      createLabel_title: 'Create Label',
      createNewOneOrSelectExistingOne: 'Create a new one or select<br />an existing one.',
      createProject_title: 'Create Project',
      createTextFile_title: 'Create Text File',
      currentPassword: 'Current password',
      dangerZone_title: 'Danger Zone',
      date: 'Date',
      dueDate: 'Due date',
      dueDate_title: 'Due Date',
      deleteAttachment_title: 'Delete Attachment',
      deleteBoard_title: 'Delete Board',
      deleteCard_title: 'Delete Card',
      deleteComment_title: 'Delete Comment',
      deleteLabel_title: 'Delete Label',
      deleteList_title: 'Delete List',
      deleteProject_title: 'Delete Project',
      deleteTask_title: 'Delete Task',
      deleteUser_title: 'Delete User',
      description: 'Additional Fields',
      detectAutomatically: 'Detect automatically',
      dropFileToUpload: 'Drop file to upload',
      editor: 'Editor',
      editAttachment_title: 'Edit Attachment',
      editAvatar_title: 'Edit Avatar',
      editBoard_title: 'Edit Board',
      editDueDate_title: 'Edit Due Date',
      editEmail_title: 'Edit E-mail',
      editInformation_title: 'Edit Information',
      editLabel_title: 'Edit Label',
      editPassword_title: 'Edit Password',
      editPermissions_title: 'Edit Permissions',
      editStopwatch_title: 'Edit Stopwatch',
      editUsername_title: 'Edit Username',
      email: 'E-mail',
      emailAlreadyInUse: 'E-mail already in use',
      enterCardTitle: 'Enter card title... [Ctrl+Enter] to auto-open.',
      enterDescription: 'Enter description...',
      enterFilename: 'Enter filename',
      enterListTitle: 'Enter list title...',
      enterProjectTitle: 'Enter project title',
      enterTaskDescription: 'Enter task description...',
      filterByLabels_title: 'Filter By Labels',
      filterByMembers_title: 'Filter By Members',
      fromComputer_title: 'From Computer',
      fromTrello: 'From Trello',
      general: 'General',
      hours: 'Hours',
      importBoard_title: 'Import Board',
      invalidCurrentPassword: 'Invalid current password',
      labels: 'Labels',
      language: 'Language',
      leaveBoard_title: 'Leave Board',
      leaveProject_title: 'Leave Project',
      list: 'List',
      listActions_title: 'List Actions',
      managers: 'Managers',
      managerActions_title: 'Manager Actions',
      members: 'Members',
      memberActions_title: 'Member Actions',
      minutes: 'Minutes',
      moveCard_title: 'Move Card',
      name: 'Name',
      newestFirst: 'Newest first',
      newEmail: 'New e-mail',
      newPassword: 'New password',
      newUsername: 'New username',
      noConnectionToServer: 'No connection to server',
      noBoards: 'No boards',
      noLists: 'No lists',
      noProjects: 'No projects',
      notifications: 'Notifications',
      noUnreadNotifications: 'No unread notifications.',
      oldestFirst: 'Oldest first',
      openBoard_title: 'Open Board',
      optional_inline: 'optional',
      organization: 'Organization',
      phone: 'Phone',
      preferences: 'Preferences',
      pressPasteShortcutToAddAttachmentFromClipboard:
        'Tip: press Ctrl-V (Cmd-V on Mac) to add an attachment from the clipboard.',
      project: 'Project',
      projectNotFound_title: 'Project Not Found',
      removeManager_title: 'Remove Manager',
      removeMember_title: 'Remove Member',
      searchLabels: 'Search labels...',
      searchMembers: 'Search members...',
      searchUsers: 'Search users...',
      searchCards: 'Search cards...',
      seconds: 'Seconds',
      selectBoard: 'Select board',
      selectList: 'Select list',
      selectPermissions_title: 'Select Permissions',
      selectProject: 'Select project',
      settings: 'Settings',
      sortList_title: 'Sort List',
      stopwatch: 'Stopwatch',
      subscribeToMyOwnCardsByDefault: 'Subscribe to my own cards by default',
      taskActions_title: 'Task Actions',
      tasks: 'Tasks',
      thereIsNoPreviewAvailableForThisAttachment:
        'There is no preview available for this attachment.',
      time: 'Time',
      title: 'Title',
      userActions_title: 'User Actions',
      userAddedThisCardToList: '<0>{{user}}</0><1> added this card to {{list}}</1>',
      userLeftNewCommentToCard: '{{user}} left a new comment «{{comment}}» to <2>{{card}}</2>',
      userMovedCardFromListToList: '{{user}} moved <2>{{card}}</2> from {{fromList}} to {{toList}}',
      userMovedThisCardFromListToList:
        '<0>{{user}}</0><1> moved this card from {{fromList}} to {{toList}}</1>',
      username: 'Username',
      usernameAlreadyInUse: 'Username already in use',
      users: 'Users',
      version: 'Version',
      viewer: 'Viewer',
      writeComment: 'Write a comment...',
    },

    fields: {
      "VLR_TOTAL_DO_FRETE": "Total Freight Cost",
      "VLR_DO_FRETE_TON": "Freight Cost per Ton",
      "TOTAL_COMPRADO": "Total Purchased",
      "TOTAL_RECEBIDO": "Total Received",
      "PESO_DE_ORIGEM": "Origin Weight",
      "PESO_DE_CHEGADA": "Arrival Weight",
      "DESCRICAO": "Description"
    },

    action: {
      addAnotherCard: 'Add another card',
      addAnotherList: 'Add another list',
      addAnotherTask: 'Add another task',
      addCard: 'Add card',
      addCard_title: 'Add Card',
      addComment: 'Add comment',
      addList: 'Add list',
      addMember: 'Add member',
      addMoreDetailedDescription: 'Add more detailed description',
      addTask: 'Add task',
      addToCard: 'Add to card',
      addUser: 'Add user',
      createBoard: 'Create board',
      createFile: 'Create file',
      createLabel: 'Create label',
      createNewLabel: 'Create new label',
      createProject: 'Create project',
      delete: 'Delete',
      deleteAttachment: 'Delete attachment',
      deleteAvatar: 'Delete avatar',
      deleteBoard: 'Delete board',
      deleteCard: 'Delete card',
      deleteCard_title: 'Delete Card',
      deleteComment: 'Delete comment',
      deleteImage: 'Delete image',
      deleteLabel: 'Delete label',
      deleteList: 'Delete list',
      deleteList_title: 'Delete List',
      deleteProject: 'Delete project',
      deleteProject_title: 'Delete Project',
      deleteTask: 'Delete task',
      deleteTask_title: 'Delete Task',
      deleteUser: 'Delete user',
      duplicate: 'Duplicate',
      duplicateCard_title: 'Duplicate Card',
      edit: 'Edit',
      editDueDate_title: 'Edit Due Date',
      editDescription_title: 'Edit Description',
      editEmail_title: 'Edit E-mail',
      editInformation_title: 'Edit Information',
      editPassword_title: 'Edit Password',
      editPermissions: 'Edit permissions',
      editStopwatch_title: 'Edit Stopwatch',
      editTitle_title: 'Edit Title',
      editUsername_title: 'Edit Username',
      hideDetails: 'Hide details',
      import: 'Import',
      leaveBoard: 'Leave board',
      leaveProject: 'Leave project',
      logOut_title: 'Log Out',
      makeCover_title: 'Make Cover',
      move: 'Move',
      moveCard_title: 'Move Card',
      remove: 'Remove',
      removeBackground: 'Remove background',
      removeCover_title: 'Remove Cover',
      removeFromBoard: 'Remove from board',
      removeFromProject: 'Remove from project',
      removeManager: 'Remove manager',
      removeMember: 'Remove member',
      save: 'Save',
      showAllAttachments: 'Show all attachments ({{hidden}} hidden)',
      showDetails: 'Show details',
      showFewerAttachments: 'Show fewer attachments',
      sortList_title: 'Sort List',
      start: 'Start',
      stop: 'Stop',
      subscribe: 'Subscribe',
      unsubscribe: 'Unsubscribe',
      uploadNewAvatar: 'Upload new avatar',
      uploadNewImage: 'Upload new image',
    },
  },
};
